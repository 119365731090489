import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://my-json-server.typicode.com/FloKlung/monster-list/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  getMonsters() {
    return apiClient.get('/monsters');
  },
  //Added new call
  getMonster(id) {
    return apiClient.get('/monsters/' + id);
  },
};
