<template>
  <div class="battle-flex">
    <Player
      :monster-data="monsterData"
      :received-damage="playerDamage"
      :received-experience="experienceFromEnemy"
      @attackEnemy="attackEnemy"
    ></Player>
    <Enemy
      :received-damage="enemyDamage"
      @attackPlayer="attackPlayer"
      @experience="experience"
    ></Enemy>
  </div>
</template>

<script>
import Player from '@/components/Player.vue';
import Enemy from '@/components/Enemy.vue';
import MonsterDB from '@/services/MonsterDB.js';

export default {
  name: 'BattleArea',
  components: {
    Player,
    Enemy,
  },
  props: {
    monsterId: String,
  },
  data() {
    return {
      monsterData: null,
      enemyDamage: 0,
      playerDamage: 0,
      experienceFromEnemy: 0,
    };
  },
  created() {
    MonsterDB.getMonster(this.monsterId)
      .then((response) => {
        this.monsterData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    attackEnemy: function (damage) {
      this.enemyDamage = damage;
    },
    attackPlayer: function (playerDamage) {
      this.playerDamage = playerDamage;
    },
    experience: function (experience) {
      this.experienceFromEnemy += experience;
    },
  },
};
</script>

<style>
.battle-flex {
  display: flex;
  justify-content: space-around;
  padding-bottom: 50px;
}
</style>
