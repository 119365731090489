<template>
  <div class="character-flex">
    <h2>{{ level }}</h2>
    <div class="progress red">
      <div class="determinate green" :style="{ width: life + '%' }"></div>
    </div>
    <h2>{{ life }}</h2>
    <h2>{{ receivedDamage }}</h2>
    <img src="../assets/monster/BloodFeral.png" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Enemy',
  props: {
    receivedDamage: {
      type: Number,
      default: 0,
    },
  },
  emits: ['attackPlayer', 'experience'],
  data() {
    return {
      level: 1,
      life: 100,
      attack: 0,
      critical: 15,
      supressed: 3,
      exp: 100,
    };
  },
  watch: {
    receivedDamage: function () {
      this.life -= this.receivedDamage;
      this.calcDamage();
      this.$emit('attackPlayer', this.attack);
    },
    life: function () {
      if (this.life <= 0) {
        this.$emit('experience', this.exp);
        this.life = 100;
      }
    },
  },
  methods: {
    calcDamage() {
      let newDamage = Math.floor(
        Math.random() * (this.critical - this.supressed + this.attack) +
          this.supressed
      );
      if (this.attack === newDamage) {
        newDamage = Math.floor(
          Math.random() * (this.critical - this.supressed + this.attack) +
            this.supressed
        );
      }
      this.attack = newDamage;
    },
  },
};
</script>

<style scoped>
div img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>