<template>
  <router-view/>
</template>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  background-image: url('./assets/battle-bg.jpg');
  height: 100vh;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.progress {
  position: relative;
  height: 5px;
  display: block;
  width: 100%;
  max-width: 500px;
  background-color: #acece6;
  border-radius: 2px;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
}
.green {
  background-color: #62eb55 !important;
}
.red {
  background-color: #f44336 !important;
}
.progress .determinate {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #26a69a;
  transition: width 0.3s linear;
}

.character-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
</style>
