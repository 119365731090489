<template>
  <div class="inner-monster-list">
    <router-link
      class="monster-card"
      :to="{ name: 'BattleArea', params: { monsterId: monster.id } }"
    >
      <img :src="getImgUrl(monster.img)" alt="" />
      <div class="stats">
        <h2>{{ monster.name }}</h2>
        <span>Leben: {{ monster.life }}</span>
        <span>Attack: {{ monster.attack }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    monster: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getImgUrl(pic) {
      return require('../assets/monster/' + pic);
    },
  },
};
</script>

<style scoped>
.inner-monster-list {
  width: 50%;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
}

.monster-card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #2c3e50;
  padding: 15px 0px;
  width: 80%;
  transition: 0.2s ease-in-out;
}

.monster-card:hover {
  box-shadow: 0 3px 12px 0 rgb(0 0 0 / 20%);
}

.stats {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

img {
  min-height: 120px;
  max-height: 120px;
}
</style>