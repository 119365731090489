<template>
  <div class="character-flex">
    <h2>Level {{ level }}</h2>
    <div class="progress red">
      <div class="determinate green" :style="{ width: life + '%' }"></div>
    </div>
    <h2>{{ monsterData.name }}</h2>
    <h2>Leben {{ life }}</h2>
    <h2>Erfahrung {{ experience }}</h2>
    <img :src="getImgUrl(monsterData.img)" alt="" />
    <button
      @click="
        calcDamage();
        $emit('attackEnemy', attack);
      "
    >
      Angriff
    </button>
  </div>
</template>

<script>
export default {
  name: 'Player',
  props: {
    monsterData: {
      type: Object,
      default: null,
    },
    receivedDamage: {
      type: Number,
      default: 0,
    },
    receivedExperience: {
      type: Number,
      default: 0,
    },
  },
  emits: ['attackEnemy'],
  data() {
    return {
      level: 1,
      life: 100,
      levelUpLife: 100,
      attack: 0,
      critical: 15,
      supressed: 3,
      experience: 0,
    };
  },
  watch: {
    receivedDamage: function () {
      this.life -= this.receivedDamage;
    },
    receivedExperience: function () {
      this.experience = this.receivedExperience / this.level;
    },
    experience: function () {
      let requiredExperience = 100 * this.level;
      if (this.experience >= requiredExperience) {
        this.level++;
        this.levelUpLife += 5;
        this.life = this.levelUpLife;
        this.attack += 2;
        this.critical += 3;
        this.supressed += 1;
      }
    },
    life: function () {
      if (this.life <= 0) {
        this.life = this.levelUpLife;
      }
    },
  },
  methods: {
    calcDamage() {
      let newDamage = Math.floor(
        Math.random() * (this.critical - this.supressed + this.attack) +
          this.supressed
      );
      if (this.attack === newDamage) {
        newDamage = Math.floor(
          Math.random() * (this.critical - this.supressed + this.attack) +
            this.supressed
        );
      }
      this.attack = newDamage;
    },
    getImgUrl(pic) {
      return require('../assets/monster/' + pic);
    },
  },
};
</script>

<style scoped>
</style>