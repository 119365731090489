<template>
  <div class="monster-area">
    <div class="monster-list">
      <MonsterCard
        v-for="monster in monsters"
        :key="monster.id"
        :monster="monster"
      ></MonsterCard>
    </div>
  </div>
</template>

<script>
import MonsterCard from '@/components/MonsterCard.vue';
import MonsterDB from '@/services/MonsterDB.js';

export default {
  name: 'ChooseMonster',
  components: {
    MonsterCard,
  },
  data() {
    return {
      monsters: null,
    };
  },
  created() {
    MonsterDB.getMonsters()
      .then((response) => {
        this.monsters = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.monster-area {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.monster-list {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 2px solid #000;
  padding: 40px 0px;
}
</style>
